import React from "react"
import { Link } from "gatsby"
// import '../styles/index.module.css'
import Navigation from "../components/Navigation/Navigation"
import Footer from "../components/Footer/Footer"
import styles from '../styles/index.module.css'
import Header from "../components/Header/Header"

const Home = () => (
    <div className={styles.container}>
        <Header title={'Home  | BDEI'}/>
        <div className={styles.backdrop}/>
        <Navigation/>
        <div className={styles.content}>
            <h1 className={styles.content__header}>
                Basic Digital Education Initiative
            </h1>
            <p className={styles.content__subtext}>
            BDEI is an experiential learning program that aims to equip 500,000 public school students between ages 8-18 across Nigeria 
            with Cognitive skills, Problem-solving skills, Communication skills and Computer Science Education skills.
            </p>
            <Link className={styles.content__link} to='/become-a-champion'>
                Become a BDEI Champion
            </Link>
        </div>
        <Footer/>
    </div>
)

export default Home
